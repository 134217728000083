import React from "react";
import { Link ,graphql} from "gatsby"
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';

class NotesMobileIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            lang: this.props.lang,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            lang: nextProps.lang
        })
    }
    render() {
        if (this.props.location.search) {
            var url = this.props.location.search.substring(1) //get rid of "?" in querystring
            var qArray = url.split('&') //get key-value pairs
            var params = {}
            for (var i = 0; i < qArray.length; i++) {
                var pArr = qArray[i].split('=') //split key and value
                params[pArr[0]] = pArr[1]
            }
        }
        const { edges: posts } = this.state.data.allMarkdownRemark;
        return (
            <div className="mobile-activities-index">
                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }) => {
                        if (post.frontmatter.lang === this.state.lang) {
                            return (
                                < div >
                                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                                </div >
                            );
                        }
                        return null;
                    })}
                <Link to={`/mobile/notes/${i18n.t('notes') + this.props.location.search}#create`}>
                    <h3>{i18n.t('create_note')}</h3>
                </Link>
                <Link to={`/mobile/notes/${i18n.t('notes') + this.props.location.search}#list`}>
                    <h3>{i18n.t('list_note')}</h3>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotesMobileIndex);

export const pageQuery = graphql`
  query IndexQueryMobileNotes {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___priority] },
                    filter: { frontmatter:  { type: { eq:"mobile-description"} ,module: { eq:"notes"}}}) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            path
            lang
            type
            module
          }
        }
      }
    }
  }
`;
